import {
  TextFieldEntry,
  isTextFieldEntryEdited,
} from "@bpmn-io/properties-panel";
import { useService } from "bpmn-js-properties-panel";

export default function (element) {
  return [
    {
      id: "lead-time",
      element,
      component: LeadTime,
      isEdited: isTextFieldEntryEdited,
    }

  ];
}



function LeadTime(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.leadtime || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      leadtime: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Time taken between this task and last task (in minutes)"
      )}
      label={translate("Lead time")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}



