import {
  TextFieldEntry,
  isTextFieldEntryEdited,
} from "@bpmn-io/properties-panel";
import { useService } from "bpmn-js-properties-panel";

export default function (element) {
  return [
    {
      id: "process-time",
      element,
      component: ProcessTime,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "lead-time",
      element,
      component: LeadTime,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "pca",
      element,
      component: PercentCompleteAccurate,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "changeover-time",
      element,
      component: ChangeoverTime,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "number-of-operators",
      element,
      component: NumOfOperators,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "number-of-product-variations",
      element,
      component: NumOfProductVariations,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "number-of-shifts",
      element,
      component: NumOfShifts,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "work-in-process",
      element,
      component: WorkInProcess,
      isEdited: isTextFieldEntryEdited,
    },
  ];
}

function ProcessTime(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.processtime || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      processtime: value,
    });
  };

  const validate = (value) => {
    if(value == null){
      return translate("value must not be empty.");
    }
    var num = parseInt(value)
    var test = Number.isNaN(num)
    if(test){
      
      return translate("value must be a number");
    }
    
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate("Time that this process takes (in minutes)")}
      label={translate("Process time")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function LeadTime(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.leadtime || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      leadtime: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Time taken between this task and last task (in minutes)"
      )}
      label={translate("Lead time")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function PercentCompleteAccurate(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.pca || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      pca: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Percent complete and accurate reflects the % of each processes output that can be used as by the next process in the stream."
      )}
      label={translate("Percent Complete & Accurate")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function ChangeoverTime(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.changeovertime || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      changeovertime: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Time to switch between one product and another."
      )}
      label={translate("Changeover Time")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function NumOfOperators(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.numofoperators || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      numofoperators: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Number of people required to operate the process. Usually indicated by an operator icon for each operator inside the process box."
      )}
      label={translate("Number Of Operators")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function NumOfProductVariations(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.numofproductvariations || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      numofproductvariations: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "The number of product variations a process must handle."
      )}
      label={translate("Number Of Product Variations")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function NumOfShifts(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.numofshifts || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      numofshifts: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Number of shifts employed to achieve the current output of the stream."
      )}
      label={translate("Number Of Shifts")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function WorkInProcess(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.workinprocess || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      workinprocess: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Accumulation of work between or within processes."
      )}
      label={translate("Work In Process")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}



