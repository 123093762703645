import convert from "xml-js";

export const calculateMetrics = async (modeler, ids) => {
  var { xml } = await modeler.saveXML({ format: true });
  let result = convert.xml2json(xml, { compact: true, spaces: 4 });
  const obj1 = JSON.parse(result);
  var test = obj1["bpmn2:definitions"];
  var process = test["bpmn2:process"];
  ////console.log(test["bpmn2:process"]);

  let theValue = await Object.keys(process).map(function (key, index) {
    if (key == "bpmn2:task" || key == "bpmn2:endEvent") {
      //console.log("foundkey", key);
      return process[key];
    } else {
      //console.log("key", key);
      return;
    }
  });

  theValue = await theValue.filter((val) => val !== undefined);
  //console.log("the value", theValue);
  //console.log("the ids", ids);
  let atrib = await theValue[0].map((val) => val._attributes);
  //console.log(typeof theValue[1]);

  try {
    await theValue[1].map((val) => atrib.push(val._attributes));
  } catch (err) {
    //console.log(err);
    if (typeof theValue[1] === "object") {
      //console.log("object", theValue[1]);
      atrib.push(theValue[1]._attributes);
    }
  }
  // if (typeof theValue[1] === "object") {
  //   //console.log("object", theValue[1]);
  //   atrib.push(theValue[1]._attributes);
  // } else {
  //   await theValue[1].map((val) => atrib.push(val._attributes));
  // }
  let matchTest = await ids.map((id) => {
    let matchingVals = atrib.find((task) => task.id == id);
    //console.log(matchingVals);
    return matchingVals;
  });

  const timeCalc = (values) => {
    let retVal = 0;
    values.map((val) => {
      retVal += parseInt(val);
    });
    return retVal;
  };

  const pcaCalc = (values) => {
    let retVal = 0;
    values.map((val) => {
      retVal += parseInt(val);
    });
    var finalVal = retVal / values.length;
    return finalVal;
  };
  /*
  const sampledata = [
  { line: 0.1, value: 0, total: 0 },
  { line: 0.1, value: 0, total: 10 },
  { line: 0.75, value: 30, total: 40 },
  { line: 0.1, value: 20, total: 60 },
  { line: 0.75, value: 30, total: 90 },
  { line: 0.1, value: 20, total: 110 },
  { line: 0.75, value: 20, total: 130 },
  { line: 0.1, value: 10, total: 140 },
];
  */

  const doCalculations = (taskArray) => {
    const metrics = {
      processTimeTotal: 0,
      leadTimeTotal: 0,
      pcaTotal: 0,
      changeovertimeTotal: 0,
      numofoperatorsTotal: 0,
      numofproductvariationsTotal: 0,
      numofshiftsTotal: 0,
      workinprocessTotal: 0,
      graphData: [],
    };

    let processArr = taskArray.map((task) => {
      //console.log("task at l79", task);
      if (task["metric:processtime"]) {
        //console.log(task["metric:processtime"]);
        return task["metric:processtime"];
      }
    });
    processArr = processArr.filter((val) => val !== undefined);

    metrics.processTimeTotal = timeCalc(processArr);

    let leadArr = taskArray.map((task) => task["metric:leadtime"]);
    metrics.leadTimeTotal = timeCalc(leadArr);

    //make graph data
    var data = [{ line: 0.1, total: 0 }];
    let total = 0;
    let ptTotal = 0;
    let ltTotal = 0;
    for (let i = 0; i < leadArr.length; i++) {
      total = total + parseInt(leadArr[i]);
      ltTotal = ltTotal + parseInt(leadArr[i]);
      data.push({
        line: 0.1,
        ptvalue: ptTotal,
        ltvalue: ltTotal,
        total: total,
      });

      if (parseInt(processArr[i])) {
        total = total + parseInt(processArr[i]);
        ptTotal = ptTotal + parseInt(processArr[i]);
        data.push({
          line: 1,
          ptvalue: ptTotal,
          ltvalue: ltTotal,
          total: total,
        });
      }
    }
    metrics.graphData = [...data];
    //console.log("metrics graph", metrics.graphData);

    //percent complete and accurate
    let pcaArr = taskArray.map((task) => {
      if (task["metric:pca"]) {
        return task["metric:pca"];
      }
    });
    pcaArr = pcaArr.filter((val) => val !== undefined);
    metrics.pcaTotal = pcaCalc(pcaArr);

    //changeovertime
    let changeovertimeArr = taskArray.map((task) => {
      if (task["metric:changeovertime"]) {
        return task["metric:changeovertime"];
      }
    });
    changeovertimeArr = changeovertimeArr.filter((val) => val !== undefined);
    metrics.changeovertimeTotal = timeCalc(changeovertimeArr);

    //number of operators numofoperators
    let numofoperatorsArr = taskArray.map((task) => {
      if (task["metric:numofoperators"]) {
        return task["metric:numofoperators"];
      }
    });
    numofoperatorsArr = numofoperatorsArr.filter((val) => val !== undefined);
    metrics.numofoperatorsTotal = timeCalc(numofoperatorsArr);

    // numofproductvariations
    let numofproductvariationsArr = taskArray.map((task) => {
      if (task["metric:numofproductvariations"]) {
        return task["metric:numofproductvariations"];
      }
    });
    numofproductvariationsArr = numofproductvariationsArr.filter(
      (val) => val !== undefined
    );
    metrics.numofproductvariationsTotal = timeCalc(numofproductvariationsArr);

    // numofshifts
    let numofshiftsArr = taskArray.map((task) => {
      if (task["metric:numofshifts"]) {
        return task["metric:numofshifts"];
      }
    });
    numofshiftsArr = numofshiftsArr.filter((val) => val !== undefined);
    metrics.numofshiftsTotal = timeCalc(numofshiftsArr);

    // workinprocess
    let workinprocessArr = taskArray.map((task) => {
      if (task["metric:workinprocess"]) {
        return task["metric:workinprocess"];
      }
    });
    workinprocessArr = workinprocessArr.filter((val) => val !== undefined);
    metrics.workinprocessTotal = timeCalc(workinprocessArr);

    return metrics;

    //metric:processtime: '10', metric:leadtime: '10'
  };

  let metricsObj = doCalculations(matchTest);

  //console.log("end metrics", metricsObj);
  return metricsObj;
};

/**
 *  
      metric:processtime="1"
      metric:leadtime="1" 
      metric:pca="1" 
      metric:changeovertime="1" 
      metric:numofoperators="1" 
      metric:numofproductvariations="1" 
      metric:numofshifts="1" 
      metric:workinprocess="1" 
   
 */
