const { is } = require("bpmnlint-utils");

/**
 * A rule that checks the presence of metrics.
 */
module.exports = function () {
  function check(node, reporter) {
    if (is(node, "bpmn:EndEvent")) {
      //console.log("node", node);
      const leadTime = (node.leadtime || "").trim();
      if (leadTime.length == 0) {
        reporter.report(node.id, "Element is missing metric 'Lead Time'");
      }
    }

    // ignore data objects and artifacts for now
    if (is(node, "bpmn:Task")) {
      //console.log("node", node);
      const processTime = (node.processtime || "").trim();
      const leadTime = (node.leadtime || "").trim();
      const pca = (node.pca || "").trim();
      const changeovertime = (node.changeovertime || "").trim();
      const numofoperators = (node.numofoperators || "").trim();
      const numofproductvariations = (node.numofproductvariations || "").trim();
      const numofshifts = (node.numofshifts || "").trim();
      const workinprocess = (node.workinprocess || "").trim();
      const valuecreatingtime = (node.valuecreatingtime || "").trim();
      //console.log("process", typeof processTime);
      //console.log("lead", typeof leadTime);

      if (processTime.length == 0) {
        reporter.report(node.id, "Element is missing metric 'Process Time'");
      }
      if (leadTime.length == 0) {
        reporter.report(node.id, "Element is missing metric 'Lead Time'");
      }
      if (pca.length == 0) {
        reporter.report(
          node.id,
          "Element is missing metric 'Percent Complete and Accurate'"
        );
      }
      if (changeovertime.length == 0) {
        reporter.report(node.id, "Element is missing metric 'Changeover Time'");
      }
      if (numofoperators.length == 0) {
        reporter.report(
          node.id,
          "Element is missing metric 'Number of Operators'"
        );
      }
      if (numofproductvariations.length == 0) {
        reporter.report(
          node.id,
          "Element is missing metric 'Number of Product Variations'"
        );
      }
      if (numofshifts.length == 0) {
        reporter.report(
          node.id,
          "Element is missing metric 'Number of Shifts'"
        );
      }
      if (workinprocess.length == 0) {
        reporter.report(node.id, "Element is missing metric 'Work In Process'");
      }
    }
  }

  return { check };
};
