import PaletteProvider from "bpmn-js/lib/features/palette/PaletteProvider";
export default class CustomPaletteProvider extends PaletteProvider {
  constructor(
    create,
    elementFactory,
    globalConnect,
    handTool,
    lassoTool,
    palette,
    spaceTool,
    translate
  ) {
    super(
      palette,
      create,
      elementFactory,
      spaceTool,
      lassoTool,
      handTool,
      globalConnect,
      translate
    );

    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;
  }

  getPaletteEntries(element) {
    const { create, elementFactory, translate } = this;

    return Object.assign(super.getPaletteEntries(element));
  }
}
