// Import your custom property entries.
// The entry is a text input field with logic attached to create,
import processTimeProps from "./parts/ProcessTimeProps";

import { is } from "bpmn-js/lib/util/ModelUtil";

const LOW_PRIORITY = 500;

/**
 * A provider with a `#getGroups(element)` method
 * that exposes groups for a diagram element.
 *
 * @param {PropertiesPanel} propertiesPanel
 * @param {Function} translate
 */
export default function MagicPropertiesProvider(propertiesPanel, translate) {
  // API ////////

  /**
   * Return the groups provided for the given element.
   *
   * @param {DiagramElement} element
   *
   * @return {(Object[]) => (Object[])} groups middleware
   */
  this.getGroups = function (element) {
    /**
     * We return a middleware that modifies
     * the existing groups.
     *
     * @param {Object[]} groups
     *
     * @return {Object[]} modified groups
     */
    return function (groups) {
      // Add the "magic" group
      if (is(element, "bpmn:Activity")) {
        groups.push(createMetricGroup(element, translate));
      }
      

      return groups;
    };
  };

  // registration ////////

  // Register our custom magic properties provider.
  // Use a lower priority to ensure it is loaded after
  // the basic BPMN properties.
  propertiesPanel.registerProvider(LOW_PRIORITY, this);
}

MagicPropertiesProvider.$inject = ["propertiesPanel", "translate"];

// Create the custom magic group
function createMetricGroup(element, translate) {
  // create a group called "Magic properties".
  const magicGroup = {
    id: "metric",
    label: translate("Metric properties"),
    entries: processTimeProps(element),
  };

  return magicGroup;
}
