import {
  TextFieldEntry,
  isTextFieldEntryEdited,
} from "@bpmn-io/properties-panel";
import { useService } from "bpmn-js-properties-panel";

export default function (element) {
  return [
    {
      id: "processTimeTotal",
      element,
      component: ProcessTime,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "leadTimeTotal",
      element,
      component: LeadTimeTotal,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "pcaTotal",
      element,
      component: PercentCompleteAccurateTotal,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "changeovertimeTotal",
      element,
      component: ChangeoverTimeTotal,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "numofoperatorsTotal",
      element,
      component: NumOfOperatorsTotal,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "numofproductvariationsTotal",
      element,
      component: NumOfProductVariationsTotal,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "numofshiftsTotal",
      element,
      component: NumOfShiftsTotal,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "workinprocessTotal",
      element,
      component: WorkInProcessTotal,
      isEdited: isTextFieldEntryEdited,
    },
    {
      id: "graphData",
      element,
      component: Graphdata,
      isEdited: isTextFieldEntryEdited,
    },
  ];
}

function ProcessTime(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.processTimeTotal || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      processTimeTotal: value,
    });
  };

  const validate = (value) => {
    if(value == null){
      return translate("value must not be empty.");
    }
    var num = parseInt(value)
    var test = Number.isNaN(num)
    if(test){
      
      return translate("value must be a number");
    }
    
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate("Time that this process takes (in minutes)")}
      label={translate("Process time total")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}
function LeadTimeTotal(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.leadTimeTotal || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      leadTimeTotal: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Time taken between this task and last task (in minutes)"
      )}
      label={translate("Lead time total")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}
function Graphdata(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.graphData || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      graphData: value,
    });
  };


  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Graphdata for VSM graph"
      )}
      label={translate("Graph Data")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
    />
  );
}

function PercentCompleteAccurateTotal(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.pcaTotal || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      pcaTotal: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Percent complete and accurate reflects the % of each processes output that can be used as by the next process in the stream."
      )}
      label={translate("Percent Complete & Accurate")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function ChangeoverTimeTotal(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.changeovertimeTotal || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      changeovertimeTotal: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate("Time to switch between one product and another.")}
      label={translate("Changeover Time")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function NumOfOperatorsTotal(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.numofoperatorsTotal || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      numofoperatorsTotal: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Number of people required to operate the process. Usually indicated by an operator icon for each operator inside the process box."
      )}
      label={translate("Number Of Operators")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function NumOfProductVariationsTotal(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.numofproductvariationsTotal || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      numofproductvariationsTotal: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "The number of product variations a process must handle."
      )}
      label={translate("Number Of Product Variations")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function NumOfShiftsTotal(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.numofshiftsTotal || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      numofshiftsTotal: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Number of shifts employed to achieve the current output of the stream."
      )}
      label={translate("Number Of Shifts")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}

function WorkInProcessTotal(props) {
  const { element, id } = props;

  const modeling = useService("modeling");
  const translate = useService("translate");
  const debounce = useService("debounceInput");

  const getValue = () => {
    return element.businessObject.workinprocessTotal || "";
  };

  const setValue = (value) => {
    return modeling.updateProperties(element, {
      workinprocessTotal: value,
    });
  };

  const validate = (value) => {
    if (value == null) {
      return translate("value must not be empty.");
    }
    var num = parseInt(value);
    var test = Number.isNaN(num);
    if (test) {
      ;
      return translate("value must be a number");
    }
  };

  return (
    <TextFieldEntry
      id={id}
      element={element}
      description={translate(
        "Accumulation of work between or within processes."
      )}
      label={translate("Work In Process")}
      getValue={getValue}
      setValue={setValue}
      debounce={debounce}
      validate={validate}
    />
  );
}


