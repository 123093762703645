// Import your custom property entries.
// The entry is a text input field with logic attached to create,
import dataObjProps from "../magic/parts/DataObjProps";

import { is } from "bpmn-js/lib/util/ModelUtil";

const LOW_PRIORITY = 500;

/**
 * A provider with a `#getGroups(element)` method
 * that exposes groups for a diagram element.
 *
 * @param {PropertiesPanel} propertiesPanel
 * @param {Function} translate
 */
export default function DataObjPropertiesProvider(propertiesPanel, translate) {
  // API ////////

  /**
   * Return the groups provided for the given element.
   *
   * @param {DiagramElement} element
   *
   * @return {(Object[]) => (Object[])} groups middleware
   */
  this.getGroups = function (element) {
    /**
     * We return a middleware that modifies
     * the existing groups.
     *
     * @param {Object[]} groups
     *
     * @return {Object[]} modified groups
     */
    return function (groups) {
      // Add the "magic" group
      if (is(element, "bpmn:DataStoreReference")) {
        //console.log(groups);
        groups[0].entries = [...dataObjProps(element)];
      }

      return groups;
    };
  };

  // registration ////////

  // Register our custom magic properties provider.
  // Use a lower priority to ensure it is loaded after
  // the basic BPMN properties.
  propertiesPanel.registerProvider(LOW_PRIORITY, this);
}

DataObjPropertiesProvider.$inject = ["propertiesPanel", "translate"];

// Create the custom magic group
function createMetricGroup(element, translate) {
  // create a group called "Magic properties".
  const magicGroup = {
    id: "metric",
    label: translate("Metric properties"),
    entries: dataObjProps(element),
  };

  return magicGroup;
}
