//something strange happening
import $ from "jquery";
import { gapi } from "gapi-script";
import { getFile, addFile, updateFile } from "./google";
import CustomPaletteProvider from "./Pallet";

import lintModule from "bpmn-js-bpmnlint";
import bpmnlintConfig from "./bpmnlint-config.js";
import "bpmn-js-bpmnlint/dist/assets/css/bpmn-js-bpmnlint.css";
import "@bpmn-io/properties-panel/assets/properties-panel.css";
//import "camunda-bpmn-js/dist/assets/camunda-cloud-modeler.css";
//import BpmnModeler from "camunda-bpmn-js/lib/camunda-cloud/Modeler";
import BpmnModeler from "bpmn-js/lib/Modeler";
import TokenSimulationModule from "bpmn-js-token-simulation";

//import "camunda-bpmn-js/dist/assets/camunda-platform-modeler.css";
import "bpmn-js-token-simulation/assets/css/bpmn-js-token-simulation.css";
import diagramXML from "../resources/newDiagram.bpmn";
// import diagramXML from "../resources/fixed.bpmn";
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
} from "bpmn-js-properties-panel";
import magicPropertiesProviderModule from "./provider/magic";
import dataObjPropertiesProviderModule from "./provider/dataObj";
import magicModdleDescriptor from "./descriptors/magic";
import dataModdleDescriptor from "./descriptors/dataObjRef";
import EndNodeProviderModule from "./provider/magic/EndNode";

import extra from "./extensions/extra.json";
import { SimpleEntry } from "@bpmn-io/properties-panel";
//import calculateMetrics from metrics
import { calculateMetrics } from "./metrics/metrics";

var modeler;
var signedInUser;
var modeling;

var { host } = window.location;
var testCase = (expr) => {
  switch (expr) {
    case "staging.vipop.ie":
      return "https://staging.vipop.ie";

    case "prod.vipop.ie":
      return "https://prod.vipop.ie";

    case "vipop.jamesorourkedev.com":
      return "https://vipop.jamesorourkedev.com";  

    case "localhost:3000":
      return "http://localhost:3000";
  }
};
//console.log("host", host);
const result =
  host == "staging.vipop.ie:3000"
    ? "https://staging.vipop.ie"
    : host == "prod.vipop.ie:3000"
    ? "https://prod.vipop.ie"
    : host == "localhost:8080"
    ? "http://localhost:3000"
    : host == "vipop.jamesorourkedev.com"
    ? "https://bpmn.jamesorourkedev.com"
    : null;

const entriesList = {
  "hand-tool": {
    group: "tools",
    className: "bpmn-icon-hand-tool",
    title: "Activate the hand tool",
    action: {},
  },
  "lasso-tool": {
    group: "tools",
    className: "bpmn-icon-lasso-tool",
    title: "Activate the lasso tool",
    action: {},
  },
  "space-tool": {
    group: "tools",
    className: "bpmn-icon-space-tool",
    title: "Activate the create/remove space tool",
    action: {},
  },
  "global-connect-tool": {
    group: "tools",
    className: "bpmn-icon-connection-multi",
    title: "Activate the global connect tool",
    action: {},
  },
  "tool-separator": { group: "tools", separator: true },
  "create.start-event": {
    group: "event",
    className: "bpmn-icon-start-event-none",
    title: "Create StartEvent",
    action: {},
  },
  "create.intermediate-event": {
    group: "event",
    className: "bpmn-icon-intermediate-event-none",
    title: "Create Intermediate/Boundary Event",
    action: {},
  },
  "create.end-event": {
    group: "event",
    className: "bpmn-icon-end-event-none",
    title: "Create EndEvent",
    action: {},
  },
  "create.exclusive-gateway": {
    group: "gateway",
    className: "bpmn-icon-gateway-none",
    title: "Create Gateway",
    action: {},
  },
  "create.task": {
    group: "activity",
    className: "bpmn-icon-task",
    title: "Create Task",
    action: {},
  },
  "create.data-object": {
    group: "data-object",
    className: "bpmn-icon-data-object",
    title: "Create DataObjectReference",
    action: {},
  },
  "create.data-store": {
    group: "data-store",
    className: "bpmn-icon-data-store",
    title: "Create DataStoreReference",
    action: {},
  },
  "create.subprocess-expanded": {
    group: "activity",
    className: "bpmn-icon-subprocess-expanded",
    title: "Create expanded SubProcess",
    action: {},
  },
  "create.participant-expanded": {
    group: "collaboration",
    className: "bpmn-icon-participant",
    title: "Create Pool/Participant",
    action: {},
  },
  "create.group": {
    group: "artifact",
    className: "bpmn-icon-group",
    title: "Create Group",
    action: {},
  },
};

const mapping = {
  "hand-tool": "hand-tool",
  "lasso-tool": "lasso-tool",
  "space-tool": "space-tool",
  "connection-multi": "global-connect-tool",
  "start-event-none": "create.start-event",
  "intermediate-event-none": "create.intermediate-event",
  "end-event-none": "create.end-event",
  "gateway-none": "create.exclusive-gateway",
  task: "create.task",
  "subprocess-expanded": "create.subprocess-expanded",
  "data-object": "create.data-object",
  "data-store": "create.data-store",
  participant: "create.participant-expanded",
  group: "create.group",
};
const setSignedInUser = (user) => {
  signedInUser = user;
};

var url = new URL(window.location);
const file = url.searchParams.get("file");
const token = url.searchParams.get("token");
const prefs = url.searchParams.get("prefs");

//console.log("url is:", url);
//console.log("file is:", file);
//console.log("prefs is", prefs);

const id =
  "256085811468-dqtbe6nehdn9vuos8bhokns18h2p3i2c.apps.googleusercontent.com";
const api = "AIzaSyBqzip2oNIoxliGT3-llrvG0wUYCpkWVt4";

const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES =
  "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata";

const updateSigninStatus = (isSignedIn) => {
  if (isSignedIn) {
    const GoogleUser = gapi.auth2.getAuthInstance().currentUser.get();

    const currentUser = GoogleUser.getBasicProfile();
    // Set the signed in user
    setSignedInUser(currentUser.getName());

    // list files if user is authenticated
  } else {
    //console.log("not signed in");
    // prompt user to sign in
    // handleAuthClick();
  }
};

const initClient = () => {
  //console.log("init");
  //console.log(`scope = ${SCOPES} and client_ID = ${id}`);
  gapi.client
    .init({
      apiKey: api,
      clientId: id,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    })
    .then(
      function () {
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

        // Handle the initial sign-in state.
        updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      },
      function (error) {}
    );
};

function updatePalette(removeObj) {
  var _getPaletteEntries = CustomPaletteProvider.prototype.getPaletteEntries;
  CustomPaletteProvider.prototype.getPaletteEntries = function (element) {
    var entries = _getPaletteEntries.apply(this);
    removeObj.forEach((ele) => {
      //console.log("deleted");
      delete entries[ele];
    });

    return entries;

    // delete entries["create.task"];
    // delete entries["create.start-event"];
    // delete entries["create.data-store"];
  };
}
CustomPaletteProvider.$inject = [
  "create",
  "elementFactory",
  "globalConnect",
  "handTool",
  "lassoTool",
  "palette",
  "spaceTool",
  "translate",
];
async function checkPrefs() {
  localStorage.removeItem("metricsObj");
  if (prefs && prefs != 0) {
    //console.log("inside if");
    const res = await getFile(prefs, token);
    const prefsArray = JSON.parse(res);
    let objectsToRemove = await prefsArray.map((ele) => {
      return mapping[ele];
    });
    //console.log(objectsToRemove);
    updatePalette(objectsToRemove);

    //console.log("pastprefs");

    modeler = window.modeler = new BpmnModeler({
      container: "#js-canvas",
      linting: {
        bpmnlint: bpmnlintConfig,
        active: true,
      },
      propertiesPanel: {
        parent: "#properties",
      },
      additionalModules: [
        {
          __init__: ["paletteProvider"],
          paletteProvider: ["type", CustomPaletteProvider],
        },
        lintModule,
        TokenSimulationModule,
        BpmnPropertiesPanelModule,
        BpmnPropertiesProviderModule,
        magicPropertiesProviderModule,
        dataObjPropertiesProviderModule,
        EndNodeProviderModule,
      ],
      moddleExtensions: {
        magic: magicModdleDescriptor,
        dataObj: dataModdleDescriptor,
        extra: extra,
      },
    });
    modeling = modeler.get("modeling");
  } else {
    //console.log("in else");

    modeler = window.modeler = new BpmnModeler({
      container: "#js-canvas",
      linting: {
        bpmnlint: bpmnlintConfig,
        active: true,
      },
      propertiesPanel: {
        parent: "#properties",
      },
      additionalModules: [
        {
          __init__: ["paletteProvider"],
          paletteProvider: ["type", CustomPaletteProvider],
        },
        lintModule,
        TokenSimulationModule,
        BpmnPropertiesPanelModule,
        BpmnPropertiesProviderModule,
        magicPropertiesProviderModule,
        dataObjPropertiesProviderModule,
        EndNodeProviderModule,
      ],
      moddleExtensions: {
        magic: magicModdleDescriptor,
        dataObj: dataModdleDescriptor,
        extra: extra,
      },
    });
    modeling = modeler.get("modeling");
  }
  //console.log("done");
}
const updateMetaData = async (token, fileId, name, folderId) => {
  //PATCH https://www.googleapis.com/drive/v3/files/fileId
  var folderID = window.localStorage.getItem("vipopID");
  //console.log(folderID);
  let bodyVal = {
    name: name,
    addParents: [folderID],
  };

  const response = await fetch(
    `https://www.googleapis.com/drive/v3/files/${fileId}?addParents=${folderId}&enforceSingleParent=true`,
    {
      method: "PATCH", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(bodyVal), // body data type must match "Content-Type" header
    }
  );

  return response.json();
};

async function saveFile(message) {
  //console.log("save function message", message);
  const { xml } = await modeler.saveXML({ format: true });
  //console.log("xml in save", xml);

  //check for ID
  if (message.id !== 0) {
    let { id } = message;
    updateFile(
      `https://www.googleapis.com/upload/drive/v3/files/${message.id}`,
      xml,
      token
    ).then((data) => {
      window.alert("File updated in google drive");
      var message = { value: "saved", id: id };
      window.parent.postMessage(message, result);
    });
  } else {
    addFile(
      "https://www.googleapis.com/upload/drive/v3/files",
      xml,
      token
    ).then((data) => {
      //console.log(data); // JSON data parsed by `data.json()` call
      updateMetaData(token, data.id, message.filename, message.folderId).then(
        (updatedFile) => {
          //console.log("updated file saved: --> ", updatedFile);
          window.alert("File added to google drive");
          var message = { value: "saved", id: updatedFile.id };
          window.parent.postMessage(message, result);
        }
      );
    });
  }
  //update or post
}

async function run() {
  await gapi.load("client:auth2", initClient);
  await checkPrefs();
  if (file !== null) {
    let xmlFile = await getFile(file, token);
    openDiagram(xmlFile);
    //console.log("file .loaded");
  } else {
    createNewDiagram();
    //console.log("newDiagram Opened");
  }
  var downloadLink = $("#js-download-diagram");
  var downloadSvgLink = $("#js-download-svg");

  // sendToUnityLink.click(function (e) {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   let para = document.querySelector("button");
  //   let classes = para.classList;
  //   if (classes[1] == "active") {
  //     usernameInput = document.getElementById("username").value;
  //     if (!usernameInput) {
  //       alert("Please insert a username in the box provided");
  //     } else {
  //       //console.log("clicked");
  //       //console.log(usernameInput);
  //       sendToUnity(xmlFile, usernameInput);
  //     }
  //   } else {
  //     alert("please supply a file or create one before sending to the device");
  //   }
  // });

  $(".buttons a").click(function (e) {
    if (!$(this).is(".active")) {
      e.preventDefault();
      e.stopPropagation();
    }
  });

  function setEncoded(link, name, data) {
    var encodedData = encodeURIComponent(data);

    if (data) {
      changeXml(data);
      link.addClass("active").attr({
        href: "data:application/bpmn20-xml;charset=UTF-8," + encodedData,
        download: name,
      });
    } else {
      link.removeClass("active");
    }
  }

  const checkCanvas = (canvas, modeling, elementRegistry) => {
    var root = canvas._rootElement;
    var canvasChildren = root.children;
    canvasChildren.forEach((child) => {
      if (
        child.type == "bpmn:DataStoreReference" &&
        child.businessObject.name.includes("SIM Values")
      ) {
        console.log("foundOne", child);
        console.log("stack", commandStack);
        var element = elementRegistry.get(child.id);
        modeling.removeElements([element]);
      }
    });
  };

  function sim(metrics) {
    var elementFactory = modeler.get("elementFactory");
    var canvas = modeler.get("canvas");
    var elementRegistry = modeler.get("elementRegistry");
    modeling = modeler.get("modeling");

    checkCanvas(canvas, modeling, elementRegistry);
    const dataObject = elementFactory.createShape({
      type: "bpmn:DataStoreReference",
    });

    dataObject.businessObject["name"] =
      "SIM Values! **Delete/edit at your own risk**";
    /** <bpmn2:dataObjectReference id="DataObjectReference_1km3cm0" dataObjectRef="DataObject_1udarox" test:processTimeTotal="100" test:leadTimeTotal="600" /> */
    //DOR:processTimeTotal
    // dataObject["DOR:processTimeTotal"] =
    //   metrics.processTimeTotal;
    // dataObject.children.add(
    //   {
    //     name: "DOR:leadTimeTotal",
    //     isAttr: true,
    //     type: "String",
    //     value: 300,
    //   },
    //   3
    // );

    dataObject.businessObject.processTimeTotal = metrics.processTimeTotal;
    dataObject.businessObject.leadTimeTotal = metrics.leadTimeTotal;
    dataObject.businessObject.pcaTotal = metrics.pcaTotal;
    dataObject.businessObject.changeovertimeTotal = metrics.changeovertimeTotal;
    dataObject.businessObject.numofoperatorsTotal = metrics.numofoperatorsTotal;
    dataObject.businessObject.numofproductvariationsTotal =
      metrics.numofproductvariationsTotal;
    dataObject.businessObject.numofshiftsTotal = metrics.numofshiftsTotal;
    dataObject.businessObject.workinprocessTotal = metrics.workinprocessTotal;
    dataObject.businessObject.graphData = JSON.stringify(metrics.graphData);
    //console.log("dataobj:", dataObject);

    modeling.createShape(
      dataObject,
      { x: 100, y: 100 },
      canvas.getRootElement()
    );

    //console.log(document);
  }
  var exportArtifacts = debounce(function () {
    saveSVG(function (err, svg) {
      setEncoded(downloadSvgLink, "diagram.svg", err ? null : svg);
    });

    saveDiagram(function (err, xml) {
      setEncoded(downloadLink, "diagram.bpmn", err ? null : xml);
    });
  }, 500);

  modeler.on("commandStack.changed", exportArtifacts);
  var commandStack = modeler.get("commandStack");
  window.addEventListener("message", (event) => {
    if (event.data.value == "button off") {
      let metricsObj = window.localStorage.getItem("metricsObj");
      //console.log("metricobj", metricsObj);
      metricsObj = JSON.parse(metricsObj);
      //console.log(metricsObj);
      if (
        confirm(
          "You have just exited Simulation mode, do you wish to replace your Metric values with the newer values"
        )
      ) {
        sim(metricsObj);
      } else {
      }
      // sim(metricsObj);
    }
    if (event.data.value == "metricIds") {
      //console.log("recieved ids:", event.data.ids);
      //calculate value
      calculateMetrics(modeler, event.data.ids).then((metricsObj) => {
        //console.log("save", metricsObj);
        window.localStorage.setItem("metricsObj", JSON.stringify(metricsObj));
      });
    }

    //console.log(`event origin= ${event.origin}, testcase= ${result}`);
    // if (event.origin === result) {
      if (event.origin === "https://vipop.jamesorourkedev.com" || event.origin === "https://bpmn.jamesorourkedev.com") {

      //console.log("inside if testcase");
      // if (event.origin === "https://staging.vipop.ie") {
      // https://staging.vipop.ie
      //console.log("window event shown", event.data.value);
      let value = event.data.value;

      switch (value) {
        case "undo":
          commandStack.undo();
          break;
        case "redo":
          commandStack.redo();
          break;
        case "save":
          //console.log("save, filename", event.data.filename);
          // get file data
          saveFile(event.data);
          break;
        default:
        //console.log(event);
      }
    }
  });
}

run();

// var sendToUnityLink = $("#js-send-to-unity");
// sendToUnityLink.addClass("test");
// var usernameInput = document.getElementById("username").value;

var xmlFile = "";

function createNewDiagram() {
  openDiagram(diagramXML);
}

function zoomReset() {
  window.modeler.get("zoomScroll").reset();
}
function zoomIn(e) {
  window.modeler.get("zoomScroll").stepZoom(1);
}
function zoomOut(e) {
  window.modeler.get("zoomScroll").stepZoom(-1);
}

async function openDiagram(xml) {
  await modeler
    .importXML(xml)
    .then(({ warnings }) => {
      if (warnings.length) {
        //console.log(warnings);
      }

      const canvas = modeler.get("canvas");

      canvas.zoom("fit-viewport");
    })
    .catch((err) => {
      //console.log(err);
    });
}

function saveSVG(done) {
  modeler.saveSVG(done);
}

function sendToUnity(file, username) {
  var xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
  var theUrl = "https://staging.vipop.ie";
  xmlhttp.open("POST", theUrl);
  xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  xmlhttp.send(JSON.stringify({ uname: username, body: file }));
}

function saveDiagram(done) {
  modeler.saveXML({ format: true }, function (err, xml) {
    done(err, xml);
  });
}

function changeXml(xml) {
  xmlFile = xml;
}

function testSim(metrics) {
  var elementFactory = modeler.get("elementFactory");
  var canvas = modeler.get("canvas");
  const dataObject = elementFactory.createShape({
    type: "bpmn:DataObjectReference",
  });

  dataObject.businessObject["name"] =
    "SIM Values! **Delete/edit at your own risk**";
  /** <bpmn2:dataObjectReference id="DataObjectReference_1km3cm0" dataObjectRef="DataObject_1udarox" test:processTimeTotal="100" test:leadTimeTotal="600" /> */
  //DOR:processTimeTotal
  // dataObject["DOR:processTimeTotal"] =
  //   metrics.processTimeTotal;
  // dataObject.children.add(
  //   {
  //     name: "DOR:leadTimeTotal",
  //     isAttr: true,
  //     type: "String",
  //     value: 300,
  //   },
  //   3
  // );

  dataObject.businessObject.processTimeTotal = metrics.processTimeTotal;
  dataObject.businessObject.leadTimeTotal = metrics.leadTimeTotal;
  dataObject.businessObject.pcaTotal = metrics.pcaTotal;
  dataObject.businessObject.changeovertimeTotal = metrics.changeovertimeTotal;
  dataObject.businessObject.numofoperatorsTotal = metrics.numofoperatorsTotal;
  dataObject.businessObject.numofproductvariationsTotal =
    metrics.numofproductvariationsTotal;
  dataObject.businessObject.numofshiftsTotal = metrics.numofshiftsTotal;
  dataObject.businessObject.workinprocessTotal = metrics.workinprocessTotal;
  dataObject.businessObject.graphData = JSON.stringify(metrics.graphData);
  //console.log("dataobj:", dataObject);
  modeling = modeler.get("modeling");
  modeling.createShape(dataObject, { x: 100, y: 100 }, canvas.getRootElement());

  //console.log(document);
}

function localhostBypass() {
  let metricsObj = window.localStorage.getItem("metricsObj");
  //console.log("metricobj", metricsObj);
  metricsObj = JSON.parse(metricsObj);
  //console.log(metricsObj);
  testSim(metricsObj);
}
$(function () {});

// helpers //////////////////////
$("#js-sim").click(function (e) {
  //sim();
  localhostBypass();
});

$("#zoomOut").click(function (e) {
  zoomOut();
});

$("#zoomIn").click(function (e) {
  zoomIn();
});

$("#zoomReset").click(function (e) {
  zoomReset();
});
function debounce(fn, timeout) {
  var timer;

  return function () {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(fn, timeout);
  };
}
// createNewDiagram();
